import React, {useEffect, useState} from 'react';
import UserApi from "../../../services/api/UserApi";
import {
  setCanManageSubscription,
  setIsSalesforceContact,
  setIsSubscriptionOwner,
  setOrganizationMembers,
  setOrganizationService,
  setService,
  setSubscription,
  setSubscriptionBillingDetails,
  setSubscriptionCard,
  setSubscriptionPrice, setSubscriptionPrices,
  setSubscriptionScheduledAmendment, setSubscriptionTransactions,
} from "../../../store/subscription/subscriptionSlice";
import OrganizationApi from "../../../services/api/OrganizationApi";
import SettingsApi from "../../../services/api/SettingsApi";
import {PaymentType} from "../../../model/dto/organization/paymentType";
import {Service} from "../../../model/dto/userProfile/service";
import {OrganizationService} from "../../../model/dto/organization/organizationService";
import {OrganizationMember} from "../../../model/dto/organization/organizationMember";
import {SubscriptionDetails} from "../../../model/dto/organization/subscriptionDetails";
import {PermissionsType, usePermissions} from "../../../hooks/Permissions";
import {SubscriptionBillingDetails} from "../../../model/dto/organization/subscriptionBillingDetails";
import {useSelector} from "react-redux";
import {selectUserProfile} from "../../../store/account/accountSlice";
import {useAppDispatch} from "../../../store/store";
import {useNavigate} from "react-router-dom";
import SubscriptionApi from "../../../services/api/SubscriptionApi";
import {ServicePrice} from "../../../model/dto/organization/servicePrice";

export const useLicenseInformation = (id: string) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const {hasPermissions} = usePermissions();
  const profile = useSelector(selectUserProfile);

  useEffect(() => {
      const abort = new AbortController();
      UserApi.IsSfContact(abort.signal).then(isSfContact => dispatch(setIsSalesforceContact(isSfContact)))
      Promise.all([
        OrganizationApi.GetOrganizationMembers(abort.signal),
        OrganizationApi.GetOrganizationService(id!, abort.signal)
      ])
        .then(([members, orgService]) =>
          SettingsApi.GetService(orgService.serviceId, abort.signal).then((service) => ({
            service,
            orgService,
            members
          })))
        .then(({service, orgService, members}) =>
          orgService.subscription?.paymentType == PaymentType.Paddle ||
          !!orgService.subscription?.subscriptionNumber
            ? SubscriptionApi.GetSubscriptionDetails(orgService.id, abort.signal).then(subscription => ({
              service,
              orgService,
              members,
              subscription
            }))
            : Promise.resolve({
              service,
              orgService,
              members,
              subscription: null,
            } as {
              service: Service,
              orgService: OrganizationService,
              members: OrganizationMember[],
              subscription: SubscriptionDetails | null
            })
        )
        .then(({service, orgService, members, subscription}) =>
          orgService.subscription?.paymentType == PaymentType.Paddle ||
          !!orgService.subscription?.subscriptionNumber
            ? SubscriptionApi.GetPrices(service.id,
              profile?.currentOrganization?.customerNo!,
              profile?.currentOrganization?.useOrganizationAddressForBilling
                ? profile?.currentOrganization?.address!
                : profile?.currentOrganization?.billingAddress!,
              abort.signal)
              .then(prices => ({
                service,
                orgService,
                members,
                subscription,
                prices
              }))
            : Promise.resolve({
              service,
              orgService,
              members,
              subscription,
              prices: [] as ServicePrice[]
            })
        )
        .then(({service, orgService, members, subscription, prices}) => {
            dispatch(setCanManageSubscription(hasPermissions(PermissionsType.Subscription, orgService.id)))
            dispatch(setIsSubscriptionOwner(hasPermissions(PermissionsType.SubscriptionOwner, orgService.id)))

            dispatch(setSubscriptionBillingDetails(
              subscription?.billingDetails ??
              {
                email: profile?.currentOrganization?.billingEmailAddress,
                firstName: profile?.currentOrganization?.billingContactPersonFirstName,
                lastName: profile?.currentOrganization?.billingContactPersonLastName,
              } as SubscriptionBillingDetails))
            dispatch(setOrganizationMembers(members));
            dispatch(setOrganizationService(orgService));
            dispatch(setSubscription(orgService.subscription));
            dispatch(setService(service));
            dispatch(setSubscriptionCard(subscription?.card));
            dispatch(setSubscriptionPrice(subscription?.price));
            dispatch(setSubscriptionScheduledAmendment(subscription?.amendment));
            dispatch(setSubscriptionPrices(prices));
            dispatch(setSubscriptionTransactions(subscription?.transactions?.filter(a => !!a.amount)));
          }
        )
        .catch(() => {
          if (!abort.signal.aborted) {
            navigate('/licenses');
          }
        })
        .finally(() => setLoading(false));

      return () => abort.abort();
    },
    [id]
  );

  return {loading};
};
