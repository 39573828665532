import { Banner, BREAKPOINTS, COLORS, LoadingIndicator, PageWidth, Size, ComponentM, ComponentTextStyle, ComponentS, ComponentXL, BackButton, DropdownButton } from '@laerdal/life-react-components';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { CaseDetailsDto } from '../../model/dto/cases/caseDetailsDto';
import CaseApi from '../../services/api/CaseApi';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import moment from 'moment';
import { CaseEmailDto } from '../../model/dto/cases/caseEmailDto';
import CaseEmail from './CaseEmail';
import CaseStatus from './CaseStatus';
import { useSelector } from 'react-redux';
import { selectUserProfile } from '../../store/account/accountSlice';
import { StyledPageWidth } from '../_commonComponents/StyledComponents';
import CreateComment from './CreateComment';
import { CaseStatuses } from '../../model/constants/CaseStatuses';

const Title = styled(ComponentM)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  flex-grow: 1;
`;

const SecondColoumTitle = styled.div`
  padding: 12px 0 8px 0;
`;

const RowWrapper = styled.div`
  display:flex;
  justify-content: space-between;
`;
const CenterRowWrapper = styled.div`
  display:flex;
  justify-content: center;
`;

const CaseBannerWrapper = styled.div`
  margin: 24px -8px;
`;

const Columns = styled.div`
  display: flex;
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
  }
  flex-direction: column;
  gap: 32px;
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 0px 24px ;
  border-radius: 8px;
  border: 1px solid ${COLORS.neutral_200};
  background: ${COLORS.white};

  
  ${BREAKPOINTS.LARGE}{
    flex: 0 0 400px;
  }
`;

const TitleComponent = styled.div`
  display: flex;
  
  padding: 24px 0;
  align-items: center;
  align-self: stretch;
`;

const SecondColumn = styled.div`
  display:flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 0 24px ;
  border-radius: 8px;
  border: 1px solid ${COLORS.neutral_200};
  background: ${COLORS.white};
`;

const CaseDetails = styled.div`
  margin: 32px 0 0 0;
`;

const Description = styled.div`
  display:flex;
  flex-direction: column;
  padding: 8px 0;
  gap: 8px;
  overflow-wrap: anywhere;
`;


const PropertyLine = styled.div`
  display:flex;
  justify-content: space-between;
  align-self: stretch;
  padding: 8px 0;
  overflow-wrap: anywhere;
`;

const Divider = styled.div`
  margin:  32px 0;
  border-bottom: 1px solid ${COLORS.neutral_100};
  align-self: stretch;
`;

const OriginalMailFrom = styled(ComponentS)`
  margin:  10px 0 16px 0;
`;

const ResolutionWrapper = styled(ComponentS)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  border-radius: 8px;
  background-color: ${COLORS.correct_20}
`;





const CaseDetailsPage = () => {
  const navigate = useNavigate();
  const { caseNumber } = useParams<{ caseNumber: string }>();
  const user = useSelector(selectUserProfile);
  const { t, i18n } = useTranslation('Cases');

  const [loading, setLoading] = useState<boolean>(true);
  const [emailSortDescending, setEmailSortDescending] = useState<boolean>(true);
  const [caseDetails, setDetailsCase] = useState<CaseDetailsDto | undefined>(undefined);
  const [historySort, setHistorySort] = useState<string[]>([t('Most recent')]);

  const [emailsLoading, setEmailsLoading] = useState<boolean>(true);
  const [caseEmails, setEmailsCase] = useState<CaseEmailDto[] | undefined>(undefined);

  useEffect(() => {
    CaseApi.GetCaseDetails(caseNumber!, i18n.language).then((response) => {
      setDetailsCase(response);
      setLoading(false);
    });
    CaseApi.GetCaseEmails(caseNumber!).then((response) => {
      setEmailsCase(response);
      setEmailsLoading(false);
    });
  }, []);

  const formatDate = (date?: string) => {
    return date ? moment(date).format('LL') : ' ';
  };
  
  const addComment = (comment?: string) => {
    if(!comment) return;

    CaseApi.CreateComment({
      caseNumber: caseNumber ?? '',
      comment: comment
    })
    
    //@ts-ignore
      posthog.capture?.('CaseCommentAdded',{
        caseNumber: caseNumber
    });

    setEmailsCase([...caseEmails ?? [], {
      htmlBody: comment,
      messageDate: new Date(),
      fromName: user?.firstName + ' ' + user?.lastName
    }]);
  };

  return (
    <>
      <Helmet>
        <title>{t('Case details page')}</title>
      </Helmet>
      <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
        <BackButton size={Size.Small} onClick={() => navigate('/cases')}>{t('All Support Cases')}</BackButton>


        {loading && <LoadingIndicator />}
        {caseDetails &&
          <CaseDetails>
            <ComponentXL textStyle={ComponentTextStyle.Bold} >{caseDetails.subject}</ComponentXL>

            <Columns>
              <FirstColumn>
                <TitleComponent>
                  <Title textStyle={ComponentTextStyle.Bold}>{t('Case details')}</Title>
                  <CaseStatus status={caseDetails.status} statusTranslated={caseDetails.statusTranslated} />
                </TitleComponent>

                {caseDetails.description &&
                  <Description>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Description')}</ComponentS>
                    <ComponentM textStyle={ComponentTextStyle.Regular} >{caseDetails.description}</ComponentM>
                  </Description>
                }

                <Divider />

                {
                  caseDetails.caseNumber &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Case number')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.caseNumber}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.caseType &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Case type')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.caseType}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.caseOrigin &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Case origin')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.caseOrigin}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.dateOpened &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Date created')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{formatDate(caseDetails.dateOpened)}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.dateClosed &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Date closed')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{formatDate(caseDetails.dateClosed)}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.supportReason &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Reason')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.supportReason}</ComponentS>
                  </PropertyLine>
                }

                <Divider />

                {
                  caseDetails.accountNumber &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Account number')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.accountNumber}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.accountName &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Account name')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.accountName}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.contactName &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Created by')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.contactName}</ComponentS>
                  </PropertyLine>
                }

                <Divider />

                {
                  caseDetails.customerAssetName &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Asset')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.customerAssetName}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.productNumber &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Product number')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.productNumber}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.productName &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Product name')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.productName}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.serialNumber &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Serial number')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.serialNumber}</ComponentS>
                  </PropertyLine>
                }
              </FirstColumn>

              <SecondColumn>
                <SecondColoumTitle>
                  <RowWrapper>
                    <OriginalMailFrom>
                      <ComponentM textStyle={ComponentTextStyle.Bold}>{t('History')}</ComponentM>
                    </OriginalMailFrom>

                    {!!caseEmails && caseEmails.length > 0 &&
                      <DropdownButton
                        type={'text'}
                        onClick={(value) => {
                          //@ts-ignore
                          posthog.capture?.('CaseDetailsPage HistoryOrder',{
                              order: value[0]
                          });
                          setHistorySort([value[0]])
                          setEmailSortDescending(value[0] == t('Most recent'));
                        }}
                        items={[{ value: t('Most recent') }, { value: t('Oldest') }]}
                        value={historySort} />
                    }

                  </RowWrapper>
                </SecondColoumTitle>
                
                {  
                  caseDetails.status != CaseStatuses.Resolved &&
                  !emailsLoading &&
                  <CreateComment 
                    initials={`${user?.firstName[0]}${user?.lastName[0]}`}
                    save={(comment)=> addComment(comment)} />
                }
                
                {!!caseDetails.resolution && emailSortDescending &&
                  <CaseEmail emailData={{
                    fromName: caseDetails.casePrimaryOwner,
                    messageDate: new Date(caseDetails.dateClosed ?? ''),
                    textBody: t('marked this case as resolved') + "\n" + caseDetails.resolution
                  }} />
                }

                {emailsLoading &&
                  <CenterRowWrapper>
                    <LoadingIndicator />
                  </CenterRowWrapper>
                }

                <>
                  {!!caseEmails
                    && caseEmails.sort((a, b) =>
                      emailSortDescending ?
                        (new Date(b.messageDate)).getTime() - (new Date(a.messageDate)).getTime() :
                        (new Date(a.messageDate)).getTime() - (new Date(b.messageDate)).getTime()
                    )
                      .map(c => <CaseEmail emailData={c} key={c.messageDate.toString()} />)}
                </>

                {!!caseDetails.resolution && !emailSortDescending &&
                  <CaseEmail emailData={{
                    fromName: caseDetails.casePrimaryOwner,
                    messageDate: new Date(caseDetails.dateClosed ?? ''),
                    textBody: t('marked this case as resolved') + "\n" + caseDetails.resolution
                  }} />
                }

                {!emailsLoading && caseEmails?.length == 0 &&
                  <ComponentM textStyle={ComponentTextStyle.Regular}>{t('No email communication')}</ComponentM>
                }


              </SecondColumn>
            </Columns>
          </CaseDetails>
        }
      </StyledPageWidth>
    </>);
};

export default CaseDetailsPage;