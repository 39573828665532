import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {
  BREAKPOINTS,
  Button,
  COLORS,
  ComponentLStyling,
  ComponentMStyling,
  ComponentSStyling,
  ComponentTextStyle, LoadingIndicator,
  PageWidth,
  Size,
  SystemIcons
} from "@laerdal/life-react-components";
import styled from "styled-components";
import {Trans, useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import {selectUserProfile} from "../../store/account/accountSlice";
import {useSelector} from "react-redux";
import {navigateByUrl} from "../../util/navigation-helper";
import {Service} from "../../model/dto/userProfile/service";
import {SubscriptionAmendmentOrderStatus} from "../../model/dto/organization/subscriptionAmendmentOrderStatus";
import {OrganizationService} from "../../model/dto/organization/organizationService";
import SettingsApi from "../../services/api/SettingsApi";
import SubscriptionApi from "../../services/api/SubscriptionApi";
import {useNavigate} from "react-router-dom";

const Wrapper = styled(PageWidth)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;

  ${BREAKPOINTS.LARGE} {
    width: 100%;
  }

  h1 {
    word-break: break-word;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.neutral_200};
  border-radius: 8px;
  padding: 16px;
  gap: 16px;

  ${BREAKPOINTS.MEDIUM} {
    flex-direction: row;
    padding: 24px;
    gap: 24px;
    max-width: 528px;
  }

  ${BREAKPOINTS.LARGE} {
    padding: 32px;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-width: 56px;
  width: 56px;
  height: 56px;
  border: 2px solid ${COLORS.correct_500};
  border-radius: 50%;
  color: ${COLORS.correct_500};
`;

const Header = styled.h3``;

const ReferenceNumber = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.neutral_600)}
  ${BREAKPOINTS.MEDIUM} {
    ${ComponentMStyling(ComponentTextStyle.Bold, COLORS.neutral_600)}
  }

  ${BREAKPOINTS.LARGE} {
    ${ComponentLStyling(ComponentTextStyle.Bold, COLORS.neutral_600)}
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${BREAKPOINTS.LARGE} {
    flex-direction: row;
    gap: 16px;
  }
`;


export const LicenseAmendmentSuccessPage = () => {
  const {t} = useTranslation('License');

  const user = useSelector(selectUserProfile);
  const navigate = useNavigate();

  const {id, orderId} = useParams<{ id: string, orderId: string }>();
  const [service, setService] = useState<Service>();
  const [organizationService, setOrganizationService] = useState<OrganizationService>();
  const [status, setStatus] = useState<SubscriptionAmendmentOrderStatus>();
  const [loading, setLoading] = useState(true);

  const checkStatus = (): Promise<any> => {
    return SubscriptionApi.GetAmendmentOrderStatus(orderId!)
      .then((status) => {
        setStatus(status);
        if (status != SubscriptionAmendmentOrderStatus.Success) {
          setTimeout(checkStatus, 2000);
        }
      })
      .catch(() => checkStatus());
  }

  useEffect(() => {
    const orgService = user?.currentOrganization?.services?.find(x => x.id === id);
    setOrganizationService(orgService);

    SettingsApi.GetService(orgService?.serviceId!)
      .then((service) => setService(service));
  }, [user]);

  useEffect(() => {
    checkStatus();
  }, []);

  useEffect(() => {
    setLoading(!service || !organizationService || status != SubscriptionAmendmentOrderStatus.Success);
  }, [service, organizationService, status]);

  const navigateToSubscriptionDetails = () => {
    const url = !!service?.subscriptionManagementPath
      ? new URL(service!.subscriptionManagementPath, service!.serviceUrl).toString()
      : `/licenses/${organizationService?.id}`;

    navigateByUrl(url, navigate)
  };

  const email = user?.email || '';
  const serviceName = service?.name || '';
  const poNumber = organizationService?.subscription?.poNumber;

  return (
    <>
      <Helmet>
        <title>{t('Confirmation Page')}</title>
      </Helmet>
      <Wrapper>
        {
          loading && <LoadingIndicator/>
        }
        {
          !loading &&
          <Content>
            <Icon>
              <SystemIcons.CheckMark size={'24'}/>
            </Icon>
            <Main>
              <Header>
                {t('Thank you for your order')}
              </Header>
              {
                !!poNumber &&
                <ReferenceNumber>
                  {t('Reference: {{number}}', {number: poNumber})}
                </ReferenceNumber>
              }
              <div>
                <p>
                  <Trans ns={'Subscriptions'} i18nKey={'OrderConfirmationFirstLine'}>
                    {/*@ts-ignore*/}
                    An email summary has been sent to <strong>{{email}}</strong>.
                  </Trans>
                </p>
                <p>
                  <Trans ns={'Subscriptions'} i18nKey={'OrderConfirmationSecondLine'}>
                    {/*@ts-ignore*/}
                    You can start using <strong>{{serviceName}}</strong> right away.
                  </Trans>
                </p>
                <p>
                  {t('If you have any questions about your order, then please contact our customer support team.')}
                </p>
              </div>
              <Actions>
                <Button id={'manage'}
                        variant={'secondary'}
                        size={Size.Large}
                        onClick={navigateToSubscriptionDetails}>
                  {t('View subscription')}
                </Button>
              </Actions>
            </Main>
          </Content>
        }
      </Wrapper>
    </>)
};