import React, {useContext, useState} from 'react'
import {Trans, useTranslation} from "react-i18next";
import styled from "styled-components";
import {
  Button,
  COLORS,
  ComponentLStyling,
  ComponentSStyling,
  ComponentTextStyle,
  HyperLink,
  Note,
  Size,
  ToastContext,
  UpperCase
} from "@laerdal/life-react-components";
import {useSelector} from "react-redux";
import {
  selectSubscription,
  selectSubscriptionOrganizationService,
  selectSubscriptionPrice,
  selectSubscriptionService
} from "../../../store/subscription/subscriptionSlice";
import SubscriptionApi from "../../../services/api/SubscriptionApi";
import {FailToastOptions} from "../../../model/constants/ToastConstants";
import {useNavigate} from "react-router-dom";
import {selectUserProfile} from "../../../store/account/accountSlice";
import {BillingInterval} from "../../../model/dto/userProfile/billingInterval";
import dayjs from "dayjs";
import {LicenseInformationHelper} from "../helpers/LicenseInformationHelper";
import {SubscriptionStatus} from "../../../model/dto/organization/subscriptionStatus";

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${Note} {
    color: ${COLORS.neutral_600};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  padding: 16px 0;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid ${COLORS.neutral_200};
  color: ${COLORS.neutral_600};
`;

const PlanDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;
const PlanDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  ${ComponentLStyling(ComponentTextStyle.Bold, COLORS.black)}
`;
const PlanDetailsDescription = styled.div`
  display: flex;
  justify-content: space-between;

  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.black)}
  .note {
    color: ${COLORS.neutral_500};
  }
`;

export const LicenseAmendmentCancel = () => {
  const {t, i18n} = useTranslation('License');
  const navigate = useNavigate();
  const {addToast} = useContext(ToastContext);
  const user = useSelector(selectUserProfile);
  const orgService = useSelector(selectSubscriptionOrganizationService);
  const service = useSelector(selectSubscriptionService);
  const subscription = useSelector(selectSubscription);
  const price = useSelector(selectSubscriptionPrice);

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = () => {
    setSubmitting(true);
    SubscriptionApi.CancelSubscription(orgService!.id!)
      .then(() => {

        //@ts-ignore
        posthog.capture?.('AC Subscription Cancelled', {
          subscription_name: service!.name,
          subscription_number: subscription!.subscriptionNumber || subscription!.id,
          end_date:
            subscription?.status == SubscriptionStatus.Trial
            ? new Date()
              : subscription!.billingInterval == BillingInterval.Monthly
                ? subscription!.nextBillingDate
                : subscription!.expirationDate,
        })
      })
      .then(() => navigate(`/licenses/${orgService!.id}`))
      .catch(() => addToast(t('A problem occurred – please try again'), FailToastOptions))
      .finally(() => setSubmitting(false));
  }

  const country =
    user?.currentOrganization?.useOrganizationAddressForBilling
      ? user?.currentOrganization?.address?.country.codeAlpha2
      : user?.currentOrganization?.billingAddress?.country.codeAlpha2;
  const privacyPolicy = process.env.REACT_APP_PRIVACY_POLICY_URL || '';
  const termsOfService = `${process.env.REACT_APP_TERMS_OF_SERVICE_URL!}/${service!.id}?country=${country}&locale=${i18n.language}`;

  const date =
    subscription?.billingInterval != BillingInterval.Monthly
      ? subscription!.expirationDate
      : subscription!.nextBillingDate;

  const dateText = dayjs(date).format('LL');

  const amount = !!price?.intervalTotal
    ? price.intervalTotal - (price.intervalTax || 0)
    : (price?.total || 0) - (price?.tax || 0);

  const priceText = subscription?.status != SubscriptionStatus.Trial
    //@ts-ignore
    ? LicenseInformationHelper.FormatPriceExtended(t, subscription?.billingInterval, amount, price?.currency)
    : t('Free trial');
    //@ts-ignore
  const planText = LicenseInformationHelper.FormatServicePlan(t, service, subscription);
  //@ts-ignore
  const description = LicenseInformationHelper.FormatTierDescription(t, service, subscription?.plan?.id, subscription?.tier?.id, subscription?.maxSubscriptionInstances);

  return (
    <>
      {
        subscription?.status != SubscriptionStatus.Trial &&
        <h5>{t('Cancel your plan')}</h5>
      }
      {
        subscription?.status == SubscriptionStatus.Trial &&
        <h5>{t('Cancel your trial')}</h5>
      }
      <Content>
        <Header>
          <UpperCase>{t('Current plan')}</UpperCase>
        </Header>
        <PlanDetails>
          <PlanDetailsHeader>
            <span>{planText}</span>
            <span>{priceText}</span>
          </PlanDetailsHeader>
          <PlanDetailsDescription>
            <span>{description}</span>
            {
              subscription?.status != SubscriptionStatus.Trial &&
              <span className="note">{t('excl. taxes')}</span>
            }
          </PlanDetailsDescription>
        </PlanDetails>
        {
          subscription?.status != SubscriptionStatus.Trial &&
          <p>
            <Trans ns={'License'} i18nKey={'SubscriptionCancellationNote'}>
              Your plan will be canceled, but is still available until the end of your billing period
              on {{dateText}}.<br/><br/>
              If you change your mind, you can renew your subscription.
            </Trans>
          </p>
        }
        {
          subscription?.status == SubscriptionStatus.Trial &&
          <p>
            {t('Your trial will be canceled immediately.')}<br/><br/>
            {t('If you change your mind, you can purchase a new subscription.')}
          </p>
        }

      </Content>
      <Actions>
        <Note>
          <Trans ns={'License'} i18nKey={'TermsOfServiceAndPrivacyPolicy'}>
            By canceling your plan, you agree to Laerdal’s <HyperLink variant={'default'} href={termsOfService}>Terms
            of Service</HyperLink> and <HyperLink href={privacyPolicy} variant={'default'}>Privacy Policy</HyperLink>.
          </Trans>
        </Note>
        <Button size={Size.Large} onClick={onSubmit} loading={submitting}>
          {
            subscription?.status == SubscriptionStatus.Trial &&
            t('Cancel trial')
          }
          {
            subscription?.status != SubscriptionStatus.Trial &&
            t('Cancel plan')
          }
        </Button>
        <Button size={Size.Large} onClick={() => navigate(-1)} variant={'secondary'}>{t('Go back')}</Button>
      </Actions>

    </>
  )
}