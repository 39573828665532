import {
  BREAKPOINTS,
  DropdownFilter,
  InputLabel,
  ModalDialog,
  Size,
  TextField,
} from '@laerdal/life-react-components';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useMediaMatch} from "rooks";
import { AddressDto } from '../../model/dto/address/addressDto';
import { Country } from '../../model/dto/settings/country';

const Wrapper = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const ParagraphL = styled.p`
  ${BREAKPOINTS.LARGE}{
    line-height: 32px; 
    font-size: 20px;
  }

  ${BREAKPOINTS.MEDIUM}{
    font-size: 18px;
    line-height: 28px;
  }

  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: Lato;
  font-style: normal;
`

const InputWrapper = styled.div`
`;

export interface Props {
  marketCountries: Country[],
  isOpen: boolean;
  address?: AddressDto;
  onClose: () => void;
  onSubmit: (address: AddressDto) => void;
}

const AddressEditAddModal = ({address, isOpen, onClose, onSubmit, marketCountries}: Props) => {
  const {t} = useTranslation('Address');
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  const isLargeScreen = useMediaMatch(BREAKPOINTS.LARGE.replace('@media ', ''));
  
  const [a, setA] = useState<AddressDto>(address!);

  const [billingName, setBillingName] = useState<string | undefined>(address?.billingName);
  const [additionalName, setAdditionalName] = useState<string | undefined>(address?.additionalName);
  const [line1, setLine1] = useState<string | undefined>(address?.line1);
  const [line2, setLine2] = useState<string | undefined>(address?.line2);
  const [city, setCity] = useState<string | undefined>(address?.city);
  const [country, setCountry] = useState<string | undefined>(address?.country);
  const [zipCode, setZipCode] = useState<string | undefined>(address?.zipCode);
  const [tel, setTel] = useState<string | undefined>(address?.tel);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  useEffect(() => {
    setA(address!);
    setBillingName(address?.billingName);
    setAdditionalName(address?.additionalName);
    setLine1(address?.line1);
    setLine2(address?.line2);
    setCity(address?.city);
    setCountry(address?.country);
    setZipCode(address?.zipCode);
    setTel(address?.tel);
    setIsLoading(false);
  }, [address]);

  const close = () => {
    onClose();
  }

  const submit = () => {
    
    if (line1 === undefined) setLine1('');
    if (city === undefined) setCity('');
    if (zipCode === undefined) setZipCode('');
    if (country === undefined) setCountry('');
    
    if(country && line1 && city && zipCode && zipCode.length <= 20)
    {
      setIsLoading(true);

      a.billingName = billingName;
      a.additionalName = additionalName;
      a.line1 = line1;
      a.line2 = line2;
      a.city = city;
      a.country = country;
      a.zipCode = zipCode;
      a.tel = tel;

      onSubmit(a);
    }
  }

  function getZipCodeErrorMessage(zipCode: string | undefined): string | undefined {
    if(zipCode === undefined) return '';
    
    if(zipCode.length == 0) return t('Required')
    if(zipCode.length > 20) return t('Maximum 20 characters')
  }

  return (
    <ModalDialog
      contentOverflow={'auto'}
      isModalOpen={isOpen}
      closeAction={close}
      size={ isLargeScreen? Size.Large : 
                            isMediumScreen ? Size.Medium : Size.Small}
      closeModalAndClearInput={close}
      submitAction={(e) => e?.preventDefault()}
      buttons={[{
        variant: 'tertiary',
        action: close,
        disabled: isLoading,
        text: t('Close')
      },{
      variant: 'primary',
      action: submit,
      loading: isLoading,
      disabled: isLoading,
      text: t('Save')
    }
      ]}
      title={ t('Edit address')}>

      <Wrapper>
        <ParagraphL>
        </ParagraphL>

        <InputWrapper>
          <InputLabel inputId="billingName" text={t("Name of receiver (optional)")} size={ isMediumScreen ? Size.Medium : Size.Small} />
          <TextField 
            id="billingName"
            disabled={true} 
            placeholder={t("e.g. Annie Laerdal")}  
            size={ isMediumScreen ? Size.Medium : Size.Small} 
            value={billingName} 
            onChange={(x) => setBillingName(x)} />
        </InputWrapper>

        {/* <InputWrapper>
          <InputLabel inputId="additionalName" text={t("Attention of (optional)")} size={ isMediumScreen ? Size.Medium : Size.Small} />
          <TextField 
            id="additionalName" 
            placeholder={t("e.g. building no. 2")}  
            size={ isMediumScreen ? Size.Medium : Size.Small} 
            value={additionalName} 
            onChange={(x) => setAdditionalName(x)} />
        </InputWrapper> */}

        <InputWrapper>
          <InputLabel inputId="country" text={t("Country / Region")} size={ isMediumScreen ? Size.Medium : Size.Small} />
          <TextField 
            id="country" 
            placeholder={t("Select ...")} 
            size={ isMediumScreen ? Size.Medium : Size.Small}
            value={country} 
            disabled={true}/>
        </InputWrapper>
        
        <InputWrapper>
          <InputLabel inputId="line1" text="Address line 1" size={ isMediumScreen ? Size.Medium : Size.Small} />
          <TextField 
            id="line1" 
            placeholder={t("e.g. 167 Myers Corners Rd")} 
            size={ isMediumScreen ? Size.Medium : Size.Small}
            value={line1} 
            onChange={(x) => setLine1(x)} 
            validationMessage={line1 === undefined || line1.length > 0 ? '' : t('Required')}/>
        </InputWrapper>

        <InputWrapper>
          <InputLabel inputId="line2" text="Address line 2" size={ isMediumScreen ? Size.Medium : Size.Small} />
          <TextField 
            id="line2" 
            placeholder={t("e.g. Main Building, 2nd Floor")} 
            size={ isMediumScreen ? Size.Medium : Size.Small}
            value={line2} 
            onChange={(x) => setLine2(x)}/>
        </InputWrapper>

        <InputWrapper>
          <InputLabel 
            inputId="city" 
            text={t("City")} 
            size={ isMediumScreen ? Size.Medium : Size.Small} />
          <TextField 
            id="city" 
            placeholder={t("e.g. Wappingers Falls")} 
            size={ isMediumScreen ? Size.Medium : Size.Small}
            value={city} 
            onChange={(x) => setCity(x)} 
            validationMessage={city === undefined || city.length > 0 ? '' : t('Required')}/>
        </InputWrapper>

        <InputWrapper>
          <InputLabel 
            inputId="zipCode" 
            text={t("Zip / Postal code")} 
            size={ isMediumScreen ? Size.Medium : Size.Small} />
          <TextField 
            id="zipCode" 
            placeholder={t("e.g. 12590")} 
            size={ isMediumScreen ? Size.Medium : Size.Small} 
            value={zipCode}
            onChange={(x) => setZipCode(x)} 
            validationMessage={ getZipCodeErrorMessage(zipCode)}/>
        </InputWrapper>

        <InputWrapper>
          <InputLabel 
            inputId="tel" 
            text={t("Telephone number (optional)")} 
            size={ isMediumScreen ? Size.Medium : Size.Small} />
          <TextField 
            id="tel" 
            placeholder={t("e.g. +1 877-523-7325")} 
            size={ isMediumScreen ? Size.Medium : Size.Small} 
            value={tel} 
            onChange={(x) => setTel(x)} 
            />
        </InputWrapper>
        
        {/* <InputWrapper>
          <InputLabel inputId="email" text={t("Email (optional)")} size={ isMediumScreen ? Size.Medium : Size.Small} />
          <TextField 
            id="email" 
            placeholder={t("Email")} 
            size={ isMediumScreen ? Size.Medium : Size.Small} 
            value={email} 
            onChange={(x) => setEmail(x)} 
            />
        </InputWrapper> */}
      </Wrapper>
    </ModalDialog>);
}

export default AddressEditAddModal;