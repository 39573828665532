import {
  ComponentS,
} from '@laerdal/life-react-components';
import styled from 'styled-components';
import { AddressDto } from '../../model/dto/address/addressDto';

const Detials = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export interface Props {
  address: AddressDto;
}

const AddressFormat = ({address}: Props) => {
  return (
    <Detials>
              { address.billingName && <ComponentS>{address.billingName}</ComponentS> }
              {/* { address.additionalName && <ComponentS>{address.additionalName}</ComponentS> } */}
              { address.line1 && <ComponentS>{address.line1}</ComponentS> }
              { address.line2 && <ComponentS>{address.line2}</ComponentS> }
              { address.city && <ComponentS>{address.city}</ComponentS> }
              { address.zipCode && <ComponentS>{address.zipCode}</ComponentS> }
              { address.state && <ComponentS>{address.state}</ComponentS> }
              { address.country && <ComponentS>{address.country}</ComponentS> }
              { address.tel && <ComponentS>{address.tel}</ComponentS> }
              { address.email && <ComponentS>{address.email}</ComponentS> }
    </Detials>)
}

export default AddressFormat;