import React, {useContext, useEffect, useState} from 'react'
import {Trans, useTranslation} from "react-i18next";
import styled from "styled-components";
import {Button, COLORS, HyperLink, Note, Size, ToastContext} from "@laerdal/life-react-components";
import {useSelector} from "react-redux";
import {
  selectSubscription,
  selectSubscriptionAmendmentOptions,
  selectSubscriptionAmendmentOrder,
  selectSubscriptionHasChangedOptions,
  selectSubscriptionOrganizationService,
  selectSubscriptionService
} from "../../../store/subscription/subscriptionSlice";
import SubscriptionApi from "../../../services/api/SubscriptionApi";
import {FailToastOptions} from "../../../model/constants/ToastConstants";
import {useMatch, useNavigate} from "react-router-dom";
import {selectUserProfile} from "../../../store/account/accountSlice";
import {LicenseAmendmentSummary} from "./LicenseAmendmentSummary";
import {BillingInterval} from "../../../model/dto/userProfile/billingInterval";

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${Note} {
    color: ${COLORS.neutral_600};
  }
`;

export const LicenseAmendmentConfirm = () => {
  const {t, i18n} = useTranslation('License');
  const navigate = useNavigate();
  const {addToast} = useContext(ToastContext);
  const user = useSelector(selectUserProfile);
  const order = useSelector(selectSubscriptionAmendmentOrder);
  const options = useSelector(selectSubscriptionAmendmentOptions);
  const orgService = useSelector(selectSubscriptionOrganizationService);
  const subscription = useSelector(selectSubscription);
  const service = useSelector(selectSubscriptionService);
  const hasChangedOptions = useSelector(selectSubscriptionHasChangedOptions);

  const renew = useMatch('/licenses/:id/edit/renew/confirm');

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    !hasChangedOptions && navigate('../options', {replace: true});
  }, [hasChangedOptions]);

  const onSubmit = () => {
    setSubmitting(true);
    SubscriptionApi.CompleteAmendmentOrder(order!.sessionId!)
      .then(() => {
        if (!renew){
          const plan = service!.availablePlans!.find(p => p.id === options!.planId);
          const tier = plan!.tiers!.find(t => t.id === options!.planTierId);

          //@ts-ignore
          posthog.capture?.('AC SubscriptionAmendment Changed', {
            subscription_name: service!.name,
            subscription_number: subscription!.subscriptionNumber || subscription!.id,
            subscription_plan: plan!.name,
            subscription_tier: tier!.name,
            subscription_quantity: options!.maxSubscriptionInstances,
            subscription_type: options!.billingInterval == BillingInterval.Monthly ? 'evergreen' : 'annual',
            subscription_term:
              options!.billingInterval == BillingInterval.Monthly
                ? 'monthly'
                : options!.billingInterval == BillingInterval.ThreeYear
                  ? '3 years'
                  : options!.billingInterval == BillingInterval.FiveYear
                    ? '5 years'
                    : options!.duration == 12
                      ? '1 year'
                      : `${options!.duration! / 12} years, paid annually`,
            isautorenew: options!.autoRenew ? 'true' : 'false',
          });
        } else {
          //@ts-ignore
          posthog.capture?.('AC SubscriptionAutoRenew Enabled', {
            subscription_name: service!.name,
            subscription_number: subscription!.subscriptionNumber || subscription!.id,
          });
        }
      })
      .then(() => navigate(`/licenses/${orgService!.id}/amendment/${order!.sessionId!}`))
      .catch(() => addToast(t('A problem occurred – please try again'), FailToastOptions))
      .finally(() => setSubmitting(false));
  }

  const country =
    user?.currentOrganization?.useOrganizationAddressForBilling
      ? user?.currentOrganization?.address?.country.codeAlpha2
      : user?.currentOrganization?.billingAddress?.country.codeAlpha2;
  const privacyPolicy = process.env.REACT_APP_PRIVACY_POLICY_URL || '';
  const termsOfService = `${process.env.REACT_APP_TERMS_OF_SERVICE_URL!}/${service!.id}?country=${country}&locale=${i18n.language}`;

  return (
    <>
      <h5>{t('Confirm your selection')}</h5>
      <LicenseAmendmentSummary/>
      <Actions>
        <Note>
          <Trans ns={'License'} i18nKey={'TermsOfServiceAndPrivacyPolicy'}>
            By confirming your new plan, you agree to Laerdal’s <HyperLink variant={'default'} href={termsOfService}>Terms
            of Service</HyperLink> and <HyperLink href={privacyPolicy} variant={'default'}>Privacy Policy</HyperLink>.
          </Trans>
        </Note>
        <Button size={Size.Large}
                onClick={onSubmit}
                loading={submitting}>
          {t('Subscribe and pay')}
        </Button>
        <Button size={Size.Large} onClick={() => navigate(-1)} variant={'secondary'}>{t('Go back')}</Button>
      </Actions>

    </>
  )
}