import styled from 'styled-components';
import {
  BackButton,
  BREAKPOINTS,
  Button,
  COLORS,
  LoadingIndicator,
  PageWidth,
  SearchBar,
  Size,
  SystemIcons,
  ToastContext,
} from '@laerdal/life-react-components';
import {useNavigate} from 'react-router';
import {useSelector} from 'react-redux';
import {selectUserWebshopMarketCode} from '../../store/account/accountSlice';
import {useTranslation} from 'react-i18next';
import {useDebouncedValue} from 'rooks';
import AddressApi from '../../services/api/AddressApi';
import { useContext, useEffect, useState } from 'react';
import { AddressDto } from '../../model/dto/address/addressDto';
import { ListHeader, ListSubtitle, ListTitle, StyledPageWidth } from '../_commonComponents/StyledComponents';
import AddressTile from './AddressTile';
import AddressAddModal from './AddressAddModal';
import SettingsApi from '../../services/api/SettingsApi';
import { Country } from '../../model/dto/settings/country';
import AddressEditAddModal from './AddressEditModal';
import AddressActionModal from './AddressActionModal';
import { FailToastOptions } from '../../model/constants/ToastConstants';
import { Helmet } from 'react-helmet';
import { EmptyPageBox } from '../_commonComponents/EmptyPageBox';

const LoadingContainer = styled.div`
  padding: 50px;
`;

export const Subtitle = styled.h6`
  margin: 0;

  ${BREAKPOINTS.LARGE}{
    display: flex;
  }
`;

const SearchWrapper = styled.div`
  width: 100%;
`;

const AddressContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
`;

const AddButtonWrapper = styled.div`
  display:flex;
  & > button {
    width: 100%;
  }

  ${BREAKPOINTS.LARGE}{
    justify-content: end;
    align-items: end;
    flex-grow: 1;

    & > button {
      width: auto;
    }
  }
`;

const AddressTileWrapper = styled.div`
  width: 100%;

  ${BREAKPOINTS.LARGE}{
    width: 32% ;
  }
`;

const Row = styled.div`
  display:flex;
  flex-direction:column;
  ${BREAKPOINTS.LARGE}{
    flex-direction:row;
  }
`;

const HeaderColumn = styled.div`
  display:flex;
  flex-direction:column;
  gap:32px;
`;


const AddressPage = () => {
  const {t} = useTranslation('Address');
  const navigate = useNavigate();
  const {addToast} = useContext(ToastContext);
  
  const marketCode = useSelector(selectUserWebshopMarketCode);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchTermDebounce] = useDebouncedValue(searchTerm, 300);
  
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [hasItems, setHasItems] = useState<boolean>(false);

  const [countries, setCountries] = useState<Country[]>([]);
  const [marketCountries, setMarketCountries] = useState<Country[]>([]);
  const [filteredAdresses, setFilteredAdresses] = useState<AddressDto[]>([]);
  const [adresses, setAdresses] = useState<AddressDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedAddress, setSelectedAddress] = useState<AddressDto | undefined>();
  const [isAddAddressModalOpen, setIsAddAddresstModalOpen] = useState<boolean>(false);
  const [isDeleteAddressModalOpen, setIsDeleteAddressModalOpen] = useState<boolean>(false);
  const [isEditAddressModalOpen, setIsEditAddresstModalOpen] = useState<boolean>(false);
  const [isDefaultBillingModalOpen, setIsDefaultBillingModalOpen] = useState<boolean>(false);
  const [isDefaultShippingModalOpen, setIsDefaultShippingModalOpen] = useState<boolean>(false);

  useEffect(() => {
    AddressApi.GetList().then((response) => {
      setAdresses(response);
      setLoading(false);
      if(response?.length > 0) setHasItems(true);
      setInitialLoad(false);
    });

    SettingsApi.GetCountries().then((response) => {
      setCountries(response);
    });
    
  }, []);

  
  useEffect(() => {
    if(!!marketCode){
      SettingsApi.GetCountriesByMarket(marketCode).then((response) => {
        setMarketCountries(response);
      });
    }
    
  }, [marketCode]);
  
  useEffect(() => {
    if(adresses?.length > 0) setHasItems(true);

    setFilteredAdresses(adresses
              .filter(a=>
                !searchTermDebounce
                || (a.billingName?.toLocaleLowerCase().indexOf(searchTermDebounce?.toLocaleLowerCase()) ?? -1) > -1
                || (a.line1?.toLocaleLowerCase().indexOf(searchTermDebounce?.toLocaleLowerCase()) ?? -1) > -1
                || (a.line2?.toLocaleLowerCase().indexOf(searchTermDebounce?.toLocaleLowerCase()) ?? -1) > -1
                || (a.country?.toLocaleLowerCase().indexOf(searchTermDebounce?.toLocaleLowerCase()) ?? -1) > -1
                || (a.zipCode?.toLocaleLowerCase().indexOf(searchTermDebounce?.toLocaleLowerCase()) ?? -1) > -1
                || (a.city?.toLocaleLowerCase().indexOf(searchTermDebounce?.toLocaleLowerCase()) ?? -1) > -1
                || (a.addressNumber?.toLocaleLowerCase().indexOf(searchTermDebounce?.toLocaleLowerCase()) ?? -1) > -1));
    
  }, [searchTermDebounce, adresses]);

  useEffect(() => {
    //@ts-ignore
    posthog.capture?.('AddressPage Search',{
      term:searchTermDebounce
    });
  }, [searchTermDebounce]);
  

  const deleteAddress = (a: AddressDto) => { 
    //@ts-ignore
    posthog.capture?.('AddressDeleteModal Opened');
    setSelectedAddress(a); 
    setIsDeleteAddressModalOpen(true); 
  }
  const editAddress = (a: AddressDto) => { 
    //@ts-ignore
    posthog.capture?.('AddressEditModal Opened');
    setSelectedAddress(a); 
    setIsEditAddresstModalOpen(true); 
  }
  const setDefaultBillingAddress = (a: AddressDto) => { 
    //@ts-ignore
    posthog.capture?.('AddressDefaultBillingModal Opened');
    setSelectedAddress(a); 
    setIsDefaultBillingModalOpen(true); 
  };
  const setDefaultShippingAddress = (a: AddressDto) => { 
    //@ts-ignore
    posthog.capture?.('AddressDefaultShippingModal Opened');
    setSelectedAddress(a); 
    setIsDefaultShippingModalOpen(true); 
  }
  return (
    <>
      <Helmet>
        <title>{t('Address Book')}</title>
      </Helmet>
      <AddressActionModal 
        onClose={()=>{
          //@ts-ignore
          posthog.capture?.('AddressDeleteModal Closed');
          setIsDeleteAddressModalOpen(false)
        }} 
        onSubmit={()=> {
          //@ts-ignore
          posthog.capture?.('AddressDeleteModal Submited');
          AddressApi
            .Delete(selectedAddress?.addressId)
              .then(() =>    
                setAdresses(adresses.filter(a => a.addressId !== selectedAddress?.addressId)))
              .catch(()=> {
                //@ts-ignore
                posthog.capture?.('AddressDeleteModal Error');
                addToast(t('Something went wrong'), FailToastOptions)
              })
              .finally(() => 
                setIsDeleteAddressModalOpen(false))
          }} 
          mainButtonVariant="critical"
          mainButtonText={t("Yes, delete")}
          secondaryButtonText={t("No, cancel")}
          description={t("Are you sure you want to delete this shipping address?")}
          titleText={t('Delete address')}
          address={selectedAddress} 
          isOpen={isDeleteAddressModalOpen}/>
 
      <AddressActionModal 
        onClose={()=>{
          //@ts-ignore
          posthog.capture?.('AddressDefaultShippingModal Closed');
          setIsDefaultShippingModalOpen(false)
        }} 
        onSubmit={()=> {
          //@ts-ignore
          posthog.capture?.('AddressDefaultShippingModal Submited');
          AddressApi
            .SetDefaultShipping(selectedAddress?.addressId)
              .then(() =>             
                {
                  let updatedAddresses = [...adresses];
                  for( let i=0; i<updatedAddresses.length; i++){
                    updatedAddresses[i].shippingDefault = updatedAddresses[i].addressId == selectedAddress?.addressId;
                  } 
                  setAdresses(updatedAddresses);
                })
              .catch(()=> {
                //@ts-ignore
                posthog.capture?.('AddressDefaultShippingModal Error');
                addToast(t('Something went wrong'), FailToastOptions)
              })
              .finally(() => 
                setIsDefaultShippingModalOpen(false))
          }} 
          mainButtonVariant="primary"
          mainButtonText={t("Set as default")}
          secondaryButtonText={t("Cancel")}
          description={t("Set as the main shipping address for orders you place with Laerdal.")}
          titleText={t('Set as default address')}
          address={selectedAddress} 
          isOpen={isDefaultShippingModalOpen}/>

      <AddressAddModal 
        isOpen={isAddAddressModalOpen} 
        marketCountries={marketCountries}
        onClose={() => {
          //@ts-ignore
          posthog.capture?.('AddressAddModal Closed');
          setIsAddAddresstModalOpen(false);
        }}
        onCreate={(a : AddressDto) => {
          //@ts-ignore
          posthog.capture?.('AddressAddModal Create');
          AddressApi.Create(a).then(()=>{
            setLoading(true);
            setAdresses([]);
            setIsAddAddresstModalOpen(false)
            AddressApi.GetList().then((response) => {
              setAdresses(response);
              setLoading(false);
            });
          }).catch(()=> {
            //@ts-ignore
            posthog.capture?.('AddressAddModal Error');
            addToast(t('Something went wrong'), FailToastOptions)
          }); 
        }}/>
        
      <AddressEditAddModal 
        isOpen={isEditAddressModalOpen} 
        marketCountries={marketCountries}
        onClose={() => {
          //@ts-ignore
          posthog.capture?.('AddressEditModal Closed');
          setIsEditAddresstModalOpen(false)
        }}
        onSubmit={(a: AddressDto) => {
          //@ts-ignore
          posthog.capture?.('AddressEditModal Submited');
          AddressApi.Edit(a).then(()=>{
            setLoading(true);
            setAdresses([]);
            setIsEditAddresstModalOpen(false)
            AddressApi.GetList().then((response) => {
              setAdresses(response);
              setLoading(false);
            });
          }).catch(()=> {
            //@ts-ignore
            posthog.capture?.('AddressEditModal Error');
            addToast(t('Something went wrong'), FailToastOptions)
          }); 
        }}
        address={selectedAddress}/>
   
      <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
        <Row>
          <HeaderColumn>
            <ListHeader>    
                <BackButton size={Size.Small} onClick={() => navigate('/overview')}>{t('Back to overview')}</BackButton>
                <ListTitle>{t('Address Book')}</ListTitle>
                <ListSubtitle>{t('Store address details for receiving order deliveries.')}</ListSubtitle>
            </ListHeader>
            <ListHeader>    
                <Subtitle>{t('Delivery addresses')}</Subtitle>
                <ListSubtitle>{t('These options are available when placing an order on the Laerdal Webshop.')}</ListSubtitle>
            </ListHeader>
          </HeaderColumn>
          
          <AddButtonWrapper>
            <Button onClick={()=>{
                //@ts-ignore
                posthog.capture?.('AddressAddModal Opened');
                setIsAddAddresstModalOpen(true)
              }} 
              variant="secondary" icon={<SystemIcons.Plus/>}>
              {t('Add new address')}
              </Button>
          </AddButtonWrapper>
        </Row>

        
        { initialLoad &&
          <LoadingIndicator/>
        }

        { !initialLoad && !hasItems &&
          <EmptyPageBox 
            title={t('Your addresses')} 
            description={t('Saved address information will be displayed here.')}/>
        }
        
        { !initialLoad && hasItems &&
          <>
            <SearchWrapper>
              <SearchBar
                  enterSearch={() => {}}
                  id="addressSearchField"
                  placeholder={t('Search addresses')}
                  size={Size.Medium}
                  setSearchTerm={(term) => {
                    setLoading(false);
                    setSearchTerm(term);
                  }}
                  removeSearch={() => setSearchTerm('')}
              />
            </SearchWrapper>

            {
              loading && <LoadingContainer><LoadingIndicator/></LoadingContainer>
            }

            {   
              adresses && 
              <AddressContainer>
                {filteredAdresses
                  .map(a => 
                    <AddressTileWrapper key={a.addressId}>
                      <AddressTile 
                        address={a} 
                        deleteAddress={deleteAddress} 
                        editAddress={editAddress} 
                        setDefaultBillingAddress={setDefaultBillingAddress} 
                        setDefaultShippingAddress={setDefaultShippingAddress}  />
                    </AddressTileWrapper>
                )}
              </AddressContainer>
            }
            </>
          }

      </StyledPageWidth>
    </>
  );
};


export default AddressPage;