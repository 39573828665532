import React, {useContext, useEffect, useMemo, useState} from 'react'
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {useMatch, useNavigate} from "react-router-dom";
import {
  Button,
  COLORS,
  ComponentLStyling,
  ComponentSStyling,
  ComponentTextStyle,
  Size, ToastContext
} from "@laerdal/life-react-components";
import {useSelector} from "react-redux";
import {
  selectSubscription,
  selectSubscriptionAmendmentOptions,
  selectSubscriptionAmendmentPrice,
  selectSubscriptionHasChangedOptions,
  selectSubscriptionService, setSubscriptionAmendmentOptions, setSubscriptionAmendmentOrder,
} from "../../../store/subscription/subscriptionSlice";
import {LicenseAmendmentPlans} from "./LicenseAmendmentPlans";
import {BillingInterval} from "../../../model/dto/userProfile/billingInterval";
import {formatCurrency} from "../../../util/currency-helper";
import {useAppDispatch} from "../../../store/store";
import SubscriptionApi from "../../../services/api/SubscriptionApi";
import {selectUserProfile} from "../../../store/account/accountSlice";
import {FailToastOptions} from "../../../model/constants/ToastConstants";
import {LicenseInformationHelper} from "../helpers/LicenseInformationHelper";


const Subtotal = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${COLORS.neutral_100};
  padding-top: 16px;

  ${ComponentLStyling(ComponentTextStyle.Bold, COLORS.black)}
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .note {
    ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.neutral_500)}
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LicenseAmendmentOptions = () => {
  const {t} = useTranslation('License');
  const {addToast} = useContext(ToastContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [submitting, setSubmitting] = useState(false);

  const hasChanged = useSelector(selectSubscriptionHasChangedOptions);
  const amendmentPrice = useSelector(selectSubscriptionAmendmentPrice);
  const options = useSelector(selectSubscriptionAmendmentOptions);
  const service = useSelector(selectSubscriptionService);
  const subscription = useSelector(selectSubscription);
  const user = useSelector(selectUserProfile);

  useEffect(() => {
    if (!subscription || !options || !service) return;
    const shouldRenew = LicenseInformationHelper.ShouldAutoRenew(subscription, options, service);

    if (shouldRenew && !options.autoRenew) {
      dispatch(setSubscriptionAmendmentOptions({...options, autoRenew: true}))
    } else if (!shouldRenew && options.autoRenew && !subscription.autoRenew) {
      dispatch(setSubscriptionAmendmentOptions({...options, autoRenew: false}))
    }
  }, [subscription, options, service])

  //@ts-ignore
  const subtotalText = LicenseInformationHelper.FormatPriceExtended(t, options?.billingInterval, amendmentPrice?.price, amendmentPrice?.currency);

  const onContinue = () => {
    setSubmitting(true);
    SubscriptionApi.CreateAmendmentOrder(service!.id, user!.currentOrganization!.id, options!)
      .then((response) => {
        dispatch(setSubscriptionAmendmentOrder(response));
        navigate('confirm');
      })
      .catch(() => addToast(t('A problem occurred – please try again'), FailToastOptions))
      .finally(() => setSubmitting(false))
  }

  return (
    <>
      <h5>{t('Change your plan')}</h5>
      <LicenseAmendmentPlans/>

      <Subtotal>
        <span>{t('Subtotal')}</span>

        <div>
          <span>{subtotalText}</span>
          <span className='note'>{t('excl. taxes')}</span>
        </div>
      </Subtotal>
      <Actions>
        <Button size={Size.Large}
                onClick={onContinue}
                loading={submitting}
                disabled={!hasChanged}>
          {t('Continue')}
        </Button>
        <Button size={Size.Large}
                onClick={() => navigate('../../', {relative: 'path'})}
                variant={'secondary'}>
          {t('Go back')}
        </Button>
      </Actions>

    </>
  )
}